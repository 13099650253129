/* PRICES */
.transition_bottom {
    background: linear-gradient(#EBF2F6, #FDFDFD);
    width: 100%;
    text-align: center;
    height: 2rem;
}

.responsive_table {
    width: 60vw;
    margin-bottom: 4rem;
    margin-left: auto;
    margin-right: auto;    
}

.responsive_table table {    
    font-family: "Pridi", serif;
    color: #003459 !important;
}

table th {
    font-size: 1.5rem;
    border-bottom: 5px solid #007EA7;
    color: #003459 !important;
}

table td {    
    border-bottom: 2px solid #007da783;
}

.pricelistHeaders {
    text-align: right;
}

.pricelistTypes {
    font-size: 1.4rem;
    font-weight: 450;
    border-bottom: 1px solid white;
    padding-left: 2.5rem;
    padding-bottom: 0 !important;
    padding-top: 1.5rem !important;
}

.pricelistServices {
    font-size: 1.3rem;
    padding-left: 4rem !important;    
}

.pricelistPrices {
    font-size: 1.1rem;
    text-align: right;
}

.PriceList h2 {
    text-align: center;
    font-family: "Andika", sans-serif;
    text-transform: uppercase;
    font-size: 3rem !important;
    color: #31393C;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

@media (max-width: 1666px) {

    table th {
        font-size: 1.3rem;
    }

    .pricelistTypes {
        font-size: 1.3rem;
    }

    .pricelistServices{
        font-size: 1.2rem;
    }

    .pricelistPrices {
        font-size: 1.1rem;
    }

}

@media (max-width: 1550px) {

    .responsive_table {
        width: 75vw;
    }

    table th {
        font-size: 1.2rem;
    }

    .pricelistTypes {
        font-size: 1.2rem;
    }

    .pricelistServices{
        font-size: 1rem;
        padding-left: 3.7rem !important;
    }

    .pricelistPrices {
        font-size: 0.9rem;
    }

}

@media (max-width: 1250px) {
    .responsive_table {
        width: 85vw;
    }
}

@media (max-width: 990px) {

    .responsive_tablescroll {
        overflow-y: scroll;
        overflow-x: auto;
        height: 80%;
        width: 80%; 
    }

    .responsive_table {
        width: 95vw;
    }

    table th {
        font-size: 1.1rem;
    }

    .pricelistTypes {
        font-size: 1.1rem;
    }

    .pricelistServices{
        font-size: 0.95rem;
        padding-left: 2.9rem !important;
    }

    .pricelistPrices {
        font-size: 0.85rem;
    }
}

@media (max-width: 720px) {
    .pricelistHeaders {
        text-align: center;
    }
    
    .responsive_table table {
        background-color: white;
        z-index: 5;
        border-spacing: 0;
    }

    .responsive_table table thead tr {
        position: sticky;
        left: 0;
        top: 0;
        z-index: 2;
        padding: 1rem;
    }

    .responsive_table table thead tr:first-child {
        position: sticky;
        left: 0;
        top: 0;
        z-index: 3;
    }

    .responsive_table table thead tr th {
        position: sticky;
        left: 0;
        top: 0;
        z-index: 1;
        padding: 1rem;
    }

    .responsive_table table thead tr th:first-child {
        position: sticky;
        left: 0;
        top: 0;
        z-index: 9;
    }

    .responsive_table table tbody tr td {
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: white;
    }

    .responsive_table table tbody tr td:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        background-color: white;
    }

    .responsive_table {
        overflow: auto;
        width: 99vw;
        height: 60vh;
        margin-bottom: 3rem;
    }

    .pricelistPrices {
        padding: 0 !important;
        text-align: center;
    }
}

@media (max-width: 500px) {
    .responsive_tablescroll {
        overflow-y: scroll;
        overflow-x: auto;
        height: 80%;
        width: 10% !important;
    }
}