/* GALLERY */
img {
  image-orientation: flip;
}

.gd-img {
  image-orientation: from-image;
}

.gd-pointer {
  image-orientation: from-image;
  cursor: pointer;
}

/* Style the Image Used to Trigger the Modal */
.gd-hover {
  transition: 0.3s;
}

.gd-hover:hover {
  opacity: 0.7;
}

/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 46;
  padding-top: 0px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);  
  background-color: rgba(0, 0, 0, 0.9);
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  max-width: 85vw;
  width: auto;
  max-height: 90vh;
}

@keyframes zoom {
  from {
    transform: scale(0)
  }

  to {
    transform: scale(1)
  }
}

/* The Close Button */
.close {
  position: fixed;
  z-index: 46;
  top: 15px;
  right: 15px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
