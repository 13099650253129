@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Mono:ital,wght@0,300..700;1,300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Andika:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pridi:wght@200;300;400;500;600;700&display=swap');

body {
	margin: 0;
	box-sizing: border-box;
}

:root {
	/* Nav */
	--navBgColor1: #003459;
	--navBgColor2: #003459;
	--navLink: #ffffff;
	--navActiveLink: #00bfff;
}
.main{
    min-height: 100vh;
}
