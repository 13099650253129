/* to modify bootstrap css */
div.row{
    --bs-gutter-x:0rem;
}
.me-auto{
    margin-right: auto;
}
a.nav_link {
    font-family: "Montserrat";
    font-optical-sizing: auto;
    font-weight: 550;
    font-style: normal;
    font-size: larger;
    padding-bottom: 0.2rem;
    margin-left: 2.5rem;
    margin-right: 2rem;
}

.navbar-light {
    padding: 0px;
}

.navbar-nav {
    padding: 0px;
    margin: 0px;
    margin-left: auto;
}

.navbar-nav ul {
    padding: 0px;
    margin: 0;
}

.navbar {
    width: 100%;
    top: 0;
    left: 0;
}

.collapse{
    visibility: visible !important;
}

/*  */

.navbar1 {
    position: relative;
    z-index: 40;
    height: 65px;
    width: 100%;
    background: linear-gradient(var(--navBgColor1) 20%, var(--navBgColor2));
    font-family: "Courier Prime", monospace;
    transition: all 0.5s ease-in-out;
}

.navbar_sticky {
    position: fixed !important;
    top: 0;
    left: 0;
}

.nav_content {
    background: linear-gradient(var(--navBgColor1) 20%, var(--navBgColor2));

    width: 100%;
}

.nav_content.container {
    max-width: 100%;
}
.nav_middle{
    margin-right: auto;
}
a .navlogo{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    margin-left: 0.75rem;
    height: 4rem;
}

.nav_link {
    font-size: 1.5em;
    color: var(--navLink);
    text-decoration: none;
    overflow: hidden;
    display: inline-block;
    position: relative;
    padding: 0.5em 0;
}

.nav_link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--navLink);
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
}

.nav_link::after {
    opacity: 1;
    transform: translate3d(-101%, 0, 0);
}
.nav_link:hover::after {
    transform: translate3d(0, 0, 0);
}


.nav_link img {
    max-height: 80px;
}

/* .nav_link:hover {
    text-decoration: underline;
} */

.active_link {
    font-weight: bolder;
    color: var(--navActiveLink);
}

.navbar-toggler{
    margin-top: 0.94rem;
    margin-bottom: 0.94rem;
}

@media (max-width: 1200px) {
    .navbar{
        width: 100%;
    }
    .navbar1 {
        font-size: medium;
    }

    .nav_link{
        font-size:1.2em;
    }
}

@media (max-width: 990px) {
    .navbar1 {
        font-size: small;
        padding: 0 !important;
        height: 70px;
    }

    .navbar1 ul {
        display: contents;
        text-align: right;
    }

    .navbar-nav {
        height: 100vh;
        background: linear-gradient(var(--navBgColor2), var(--navBgColor1) 50%);
        right: 0;
        width: 0;
        transition: width 70s;
        transition-timing-function: ease-in-out;
    }

    .floatrightNavbar {
        width: 35%;
    }
    
    /* .me-auto {
        margin-right: 0 !important;
    } */

    .nav_middle{
        margin-right: 0 !important;
    }

    .navbar-collaspe {
        transition: width 2s, height 2s, transform 2s;
    }

    .nav_content.container {
        background: none;
        padding: 0;
    }

    a .navlogo{
        margin:0;
        margin-left: 0.5rem;
    }
    
    a.nav_link {
        margin-right: 1rem;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
    }
    .navbar-toggler{
        margin-right: 0.2rem;
    }

}
@media (max-width: 600px) {
    .nav-link{
        font-size:0.8rem;
    }

    .floatrightNavbar {
        width: 60%;
    }

    a.nav_link {
        font-size: 1.5rem;
    }
}

@media (max-width: 500px) {
    .nav-link{
        font-size: 0.8rem;
    }

    .floatrightNavbar {
        width: 60%;
    }

    a.nav_link {
        font-size: 1.5rem;
    }
}

@media (max-width: 450px) {
    a.nav_link {
        font-size: 1.5rem;
    }
}

@media (max-width: 380px) {
    a.nav_link {
        font-size: 1.5rem;
    }
}