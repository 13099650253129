/* ABOUT */
.aboutContainerBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2.5rem;
    margin: 2.5rem;
    margin-bottom: 5rem;
    background-color: #FDFDFD;
}

.img_div{
    width: 50%;
}

.aboutPictures {
    height: 27rem;
    width: 100%;
    object-fit: cover;
}

.aboutPictures1 {
    height: 27rem;
    width: 100%;
    object-fit: contain;
    background-color: #FDFDFD;
}

.aboutTextBox {    
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    width: 45%;
    font-family: "Red Hat Mono";
    font-optical-sizing: auto;
    font-weight: 450;
    font-style: normal;
    font-size: larger;
    background-color: #FDFDFD;
}

.aboutTextBox h2 {
    text-align: center;
    font-size: 2.25rem;
}

.aboutTextBox p {
    text-align: justify;
}

/* CAROUSEL */
.carousel .carousel-control-next,
.carousel .carousel-control-prev {
    font-size: 2rem;
    /* Increase font size for larger arrows */
    width: 3rem;
    /* Adjust width */
    height: 100%;
    /* Make the arrow cover the full height */
    background: rgba(0, 0, 0, 0.5);
    /* Add a semi-transparent background for better visibility */
    color: #fff;
    /* Change color to white for better contrast */
    top: 0;
    /* Align to the top */
    bottom: 0;
    /* Align to the bottom */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    /* Ensure arrows are above other elements */
    cursor: pointer;
    /* Change cursor to pointer */
    opacity: 0;
    /* Add slight transparency */
    transition: opacity 0.5s;
    /* Smooth transition on hover */
}

.carousel .carousel-control-next:active {
    opacity: 1;
    /* Full opacity on hover */
}

.carousel .carousel-control-prev:active {
    opacity: 1;
    /* Full opacity on hover */
}

.carousel .carousel-control-next {
    right: 0;
    /* Position the next arrow on the right */
}

.carousel .carousel-control-prev {
    left: 0;
    /* Position the previous arrow on the left */
}

/* Customize arrow styles */
.carousel .carousel-control-next-icon,
.carousel .carousel-control-prev-icon {
    padding: 0.6rem;
    width: 2.5rem;
    height: 2.5rem;
}

.carousel .carousel-control-next:before {
    border-width: 0 0.3rem 0.3rem 0;
    transform: rotate(-45deg);
    /* Points the arrow to the right */
}

.carousel .carousel-control-prev:before {
    border-width: 0 0.3rem 0.3rem 0;
    /* Same border-width as next arrow */
    transform: rotate(135deg);
    /* Points the arrow to the left */
}

@media(max-width: 1300px){
    .aboutContainerBox {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .aboutPictures {
        height: 27rem;
        width: 100%;
        object-fit: cover;
    }
    .img_div{
        width: 90%;
    }
    .aboutTextBox{
        width: 90%;
    }
}

@media (max-width: 880px) {
    .img_div {
        width: 100% !important;
    }

    .text_div {
        width: 100% !important;
    }

    .aboutPictures1 {
        height: 22rem !important;
    }

    .aboutPictures {
        height: 22rem !important;
    }
}

@media (max-width: 600px) {
    .text_div {        
        font-size: medium;
    }

    .text_div h2 {
        font-size: xx-large;
    }

    .aboutTextBox p {
        text-align: left;
    }
}

@media(max-width: 550px){
    
    .text_div h2 {
        font-size: 1.6rem !important;
    }
}