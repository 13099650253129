/* MYWORK */
.myWorkContainer {
	display: flex;
	flex-direction: column;
	gap: 2.5rem;
	align-items: center;
	padding: 4rem 0;
}

.myWorkPicturesContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 5rem;
	justify-content: center;
}

.myWorkPictures>img {
	box-shadow: 0.4rem 0.4rem 0rem 0rem rgba(0, 52, 89, 0.2);
	border-radius: 0.75rem;
	height: 24rem;
	width: 18rem;
}

.transition_top {
	background: linear-gradient(#FDFDFD, #EBF2F6);
	width: 100%;
	text-align: center;
	height: 1.5rem;
}

.MyWork h2 {
	text-align: center;
	font-family: "Andika", sans-serif;
	text-transform: uppercase;
	font-size: 3rem !important;
	color: #31393C;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.watch_gallery {
	border-radius: 25px;
	height: 3em;
	font-family: "Red Hat Mono";
	font-weight: bold;
	text-transform: uppercase;
	padding: 0.5rem;
	font-size: large;
	width: max-content !important;
}

.watch_gallery:hover {
    background-color: rgb(178, 234, 253);
    color: rgb(0 126 167);
}