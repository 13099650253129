/* COMPANY INFO */
#ceginfo>div>p {
    color: #003459;
    text-align: start;
}

#ceginfo{
    margin: 6rem;
}

@media (max-width: 800px) {
    #ceginfo>div>h3 {
        font-size: 2rem !important;
    }

    #ceginfo>div>p {
        font-size: 1.1rem !important;
    }
    #ceginfo{
        margin: 4rem;
    }
}

@media (max-width: 730px) {
    #ceginfo>div>p {
        font-size: 1rem !important;
    }
    #ceginfo{
        margin: 2rem;
    }
}

@media (max-width: 600px) {
      #ceginfo{
        margin: 1rem;
    }
}