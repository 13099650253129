@import url('https://fonts.googleapis.com/css2?family=Andika:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Andika:ital,wght@0,400;0,700;1,400;1,700&family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.footer_div {
    display: flex;
    gap: 4rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 3rem;
    margin-right: 3rem;
    justify-content: center;
    flex-wrap: wrap;
}

.footer_div>div {
    margin-left: auto;
    margin-right: auto;
}

.footer_label {
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1.5;
}

.footer_contact {
    font-family: "Courier Prime", monospace;
    font-weight: 400;
    font-style: normal;
}

.link_underline{
    text-decoration: none;
    position: relative;
    align-items: baseline;
    
}

.link_underline::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.3s ease;
}

.link_underline:hover::before {
        transform: scaleX(1);
}

.footer_contact >h2{
    display: flex;
    align-items: center;
}

.footer_contact >p{
    display: flex;
    align-items: center;

}
.footer_contact >a {
    display: flex;
    align-items: center;
}

.footer_image {
    height: 12rem;
    margin-left: auto;
    margin-right: auto;
}

.mapouter {
    margin-top: 1%;
    position: static;
    text-align: center;
    width: 100%;
    height: 18rem;
    border: 0;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 990px) {
    .footer_contact {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5%;
        width: auto !important;
    }

    .footer_div h2 {
        text-align: center;
        display: ruby-text !important;
    }
    
    .footer_div p{
        text-align: center
    }
}

@media (max-width: 800px) {
    .copyright{
        font-size: 0.8rem !important;
    }
    .footer_div h2 {
        font-size: 2rem !important;
    }

    .footer_div p {
        font-size: 1.3rem;
    }
   
    .footer_div a {
        font-size: 1.3rem;
    }
    .footer_image_div p a{
        font-size: 0.8rem !important;
    }

}

@media (max-width: 500px) {
    .copyright{
        font-size: 0.8rem !important;
    }

    .footer_div h2 {
        font-size: 1.6rem !important;
    }

    .footer_div p {
        font-size: 1.2rem;
    }

    .footer_div a {
        font-size: 1.2rem;
    }
}

@media (max-width: 390px) {
    .copyright{
        font-size: 0.8rem !important;
    }

    .footer_div h2 {
        font-size: 1.4rem !important;
    }

    .footer_div p {
        font-size: 1rem;
    }
    .footer_div a {
        font-size: 1rem;
    }

    .footer_image_div a {
        font-size: 1rem !important;
    }
}

@media (max-width: 350px) {
    .copyright{
        font-size: 0.8rem !important;
    }

    .footer_div h2 {
        font-size: 1.2rem !important;
    }

    .footer_div p {
        font-size: 0.8rem;
    }
    .footer_div a {
        font-size: 0.8rem;
    }

    .footer_image_div a {
        font-size: 0.8rem !important;
    }
}